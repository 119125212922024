import 'normalize.css';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import {SkeletonTheme} from 'react-loading-skeleton';
import {config, Hidden} from 'react-awesome-styled-grid';
import {ThemeProvider, createGlobalStyle} from 'styled-components';

import Footer from './Footer';
import {AppProvider} from '../context';
import Header from '../components/Header';
import favicon from '../images/favicon.png';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3'
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
  }

  html {
    width: 100%;
    overflow-x: hidden !important;
  }
  body {
    background-color: ${({theme}) => theme.colors.bgColor2};
    font-family: inter, sans-serif;
    overflow-x: hidden;
    font-size: 12px;
    font-family: inter, sans-serif;
    width: 100%;
    overflow-x: hidden !important;

    ${props => config(props).media['sm']`
      font-size: 16px;
    `}
    .container-header{
      height: 140px;
      width: 100%;
    }
  }

  .ReactModal__Overlay{
    background: rgba(0, 0, 0, 0.5) !important;
    z-index: 8000 !important;
  }

  html {
    ${props => config(props).media['md']`
      overflow-x: auto;
    `}
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0px;
  }

  a {
      color: ${({theme}) => theme.colors.primary};
      &:hover {
        color: ${({theme}) => theme.colors.primary};
      }
    }

  .inter {
    font-family: inter, sans-serif;
  }

  .head4-normal-medium{
    font-size: 2.5em ;
    font-weight: 500;
    ${props => config(props).media['lg']`
      font-size: 2.25em ;
    `}
  }

  .head4-normal-semibold{
    font-size: 2.5em ;
    font-weight: 600;
    ${props => config(props).media['lg']`
      font-size: 2.25em ;
    `}
  }

  .title1-normal{
    font-size: 	1.5000em;
    font-weight: 500;
  }

  .title2-normal{
    font-size: 	2.5em;
    font-weight: 600;
  }

  .flex { display: flex; }

  .flex-row { flex-direction: row; }
  .flex-col-r { flex-direction: column-reverse; }
  .flex-row-r { flex-direction: row-reverse; }

  .flex.nowrap { flex-wrap: nowrap; }
  .flex.wrap { flex-wrap: wrap; }
  .flex.wrap-reverse { flex-wrap: wrap; }

  .justify-start { justify-content: flex-start; }
  .justify-end { justify-content: flex-end; }
  .justify-center { justify-content: center; }
  .justify-between { justify-content: space-between; }
  .justify-around { justify-content: space-around; }
  .justify-evenly { justify-content: space-evenly; }

  .align-start { align-items: flex-start; }
  .align-end { align-items: flex-end; }
  .align-center { align-items: center; }
  .align-baseline { align-items: baseline; }
  .align-stretch { align-items: stretch; }

  .flex-grow { flex-grow: 1; }
  .flex-shrink { flex-shrink: 1; }
`;
const colors = {
  primary: '#202123',
  primary2: '#CDAD73',
  primary3: '#EED9B3',
  primary4: '#000000',
  secondary: '#9B9FA8',
  secondary2: '#D7D9DC',
  secondary3: '#EFF0F1',
  secondary4: '#434447',
  errorcolor: '#E93051',
  tertiary: '#201f1f',
  bgColor: '#FFFFFF',
  bgColor2: '#F3F3F3',
};

const typography = {
  fontSizes: [
    '4.125em',
    '2.875em',
    '1.875em',
    '1.25em',
    '1em',
    '0.813em',
    '0.5em',
  ],
  bodyFontFamily: 'Inter, sans-serif',
  headingFontFamily: 'Inter, sans-serif',
  headingColor: colors.primary4,
  bodyColor: colors.primary,
};

const awesomegrid = {
  paddingWidth: {
    xs: 0,
    sm: 1,
    md: 1.5,
    lg: 1.5,
    xl: 1.5,
  },
  container: {
    xs: 'full', // 'full' = max-width: 100%
    sm: 'full', // 'full' = max-width: 100%
    md: 'full', // 'full' = max-width: 100%
    lg: 'full', // max-width: 1440px
    xl: 90, // max-width: 1440px
  },
  breakpoints: {
    xs: 1,
    sm: 48, // 768px
    md: 80,
    lg: 87,
    xl: 120, // 1920px
  },
};

const Layout = ({children}) => {
  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.async = true;
    script.dataset.dataUseServiceCore = true;
    script.setAttribute('defer', 'defer');

    document.body.appendChild(script);
  }, []);

  return (
    <AppProvider>
      <SkeletonTheme>
        <ThemeProvider theme={{colors, typography, awesomegrid}}>
          <GoogleReCaptchaProvider reCaptchaKey="6Lf4ItQqAAAAAH3G9PRuFpx9UrYD-3A5xp2_y1uC" scriptProps={{ async: true }} container={{element: "container-captcha"}}>
            <Helmet>
              <title>Makers</title>
              <link name="icon" href={favicon} />
            </Helmet>
            <Header />
            <Hidden md lg xl>
              <div className="container-header" />
            </Hidden>
            <GlobalStyle />
            {children}
            <Footer />
            <div
              className="elfsight-app-a72c45d7-f27e-4347-8b6b-1e38ee01acdb"
              data-elfsight-app-lazy
            ></div>
          </GoogleReCaptchaProvider>
        </ThemeProvider>
      </SkeletonTheme>
    </AppProvider>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node,
};
